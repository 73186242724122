import{useState} from 'react';

function List() {

    // Valore della casella di input
    const[valore,setValore]=useState('');

    // State usato per l'update della pagina
    const[refresh,setRefresh]=useState('');

    // Funzione per mantenere aggiornato il valore dello state "valore"
    const getValore= event => {
        setValore(event.target.value);
        //console.log('value is:', event.target.value);

    }

    // Array con tutte le attività
    let tutto=[];

    // Blocco per l'inizializzazione dell'array "tutto" e "id"
    if(localStorage.getItem("attività") != null){
        tutto=JSON.parse(localStorage.getItem("attività"));
    }
    let id =0
    if(tutto.length>0){
        id=tutto[tutto.length-1].id+1

    }

    // Funzione per aggiunta attività in localstorage
    function put(event){
        //verifica azionamento funzione
        event.preventDefault()
        //console.log("aggiunta")
        

        //costruzione nuova attività
        let singola=new Object();
        singola.id=id;
        singola.text=valore;
        singola.stato=false;

        tutto.push(singola)

        localStorage.setItem('attività', JSON.stringify(tutto)); 
        setValore("")
    }

    // Funzione per completare un'attività e di conseguenza eliminarla
    function completa(id){
        //console.log(id);
        let index=0;
        
        for(let i=0;i<tutto.length;i++){
            if(tutto[i].id===id){
                index=i;
                break
            }
        }
        if (index > -1) { 
            tutto.splice(index, 1); 
        }
        localStorage.setItem("attività",JSON.stringify(tutto))
        setRefresh(index+Math.random())
        

    }
    // Resetta tutte le attività eliminandole
    function pulisci(){
        localStorage.clear();
        setRefresh("P")
    }

    // Render grafico della lista
    return ( 
        <>
            {/** Titolo Lista */}

            <div>
                <h1 className="text-center text-2xl mt-2 font-medium">Cose da fare</h1>
            </div>

            {/** Form */}

            <div className="block w-11/12 mx-auto mt-3">
                <form autoComplete="off" onSubmit={put}>   
                    <label htmlFor="Attività..." className="mb-2 text-sm font-medium text-gray-900 sr-only">Attività...</label>
                    <div className="relative">
                        
                        <input type="text" id="search" onChange={getValore} value={valore} className="block w-full p-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 " placeholder="Attività..." required />
                        <button type="submit" className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 ">Aggiungi</button>
                    </div>
                </form>
            </div>

            {/** Lista */}
            
            <div className="block w-11/12 mx-auto mt-3">
                <h2 className="mb-2 text-lg font-semibold text-gray-900 mt-4">Attività:</h2>
                <ul className="space-y-1  list-inside text-gray-500 ">

                {/** Scorrimento array attività con conseguente render */}

                {tutto.map((element)=>(
                    <li className="flex items-center" key={element.id}>
                        {element.text}
                        <div className="flex grow"></div>
                        <button onClick= {()=>{ completa(element.id) } } type="button" className="text-white bg-green-500 hover:bg-green-600 focus:ring-2 focus:ring-offset-1 focus:outline-none focus:ring-green-600 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 ml-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                            </svg>
                            <span className="sr-only">Icon description</span>
                        </button>
                    </li>
                ))}
                </ul>

                {/** Se l'array attività è vuoti stampo nessuna attività */}

                {id===0 ? <h2 className='text-center'>Nessuna Attività</h2>: ""}
            </div>

            {/** Bottone reset per cancellare tutte le attività */}

            <div className="block mx-auto text-center">
            <button type="button" onClick={pulisci} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mb-2 mt-6 mx-auto">Reset</button>
            </div>
            
        </>
     );
}

export default List;