import './App.css';
import List from './components/List';
document.title="Carmine To-Do"
function App() {
  return (
    <>
      <List></List>
    </>
  );
}

export default App;
